import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isNil } from 'lodash';
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import environment from '../../../environments/environment';
import classNames from '../../utils/classnames';

function Links() {
  const [superLink, setSuperLink] = useState(null);
  const [selectedLink, setSelectedLink] = useState(null);
  const [query, setQuery] = useState('');
  const { slug } = useParams();

  useEffect(() => {
    const getSuperLinks = async () => {
      const { data } = await axios.get(
        `${environment.REACT_APP_API_URL}/super-links/app/${slug}`
      );
      setSuperLink(data);
    };

    if (!isNil(slug) && isNil(superLink)) {
      getSuperLinks();
    }
  }, [superLink, slug]);

  const handleClick = () => {
    window.location.href = selectedLink?.url;
  };

  const handleSelectLink = (value) => {
    setSelectedLink(value);
  };

  const filteredLinks =
    query === ''
      ? superLink?.links
      : superLink?.links?.filter((link) =>
          link?.name?.toLowerCase().includes(query?.toLowerCase())
        );

  return (
    <div
      style={{
        backgroundColor: superLink?.styles?.backgroundColor,
        minHeight: '100dvh',
      }}
      className="upperContainer"
    >
      <div className="container">
        <div
          style={{ color: superLink?.styles?.textColor }}
          className="preTitle flex flex-col items-center"
        >
          <img
            src={superLink?.logo}
            className="w-32 h-32 mb-6"
            alt={`${superLink?.name} logo`}
          />
          Selectionnez votre établissement pour participer
        </div>
        <Combobox
          as="div"
          value={selectedLink}
          onChange={(value) => handleSelectLink(value)}
        >
          <div className="w-80 relative mt-5 mb-8">
            <ComboboxInput
              className="w-full rounded-md border-0 bg-gray-100 py-3 pl-4 pr-10 shadow-sm text-xs 2xl:text-sm sm:leading-6 border-none"
              onChange={(event) => setQuery(event.target.value)}
              displayValue={(selected) => selected?.name}
            />
            <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </ComboboxButton>
            {filteredLinks?.length > 0 && (
              <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-64 overflow-auto rounded-md bg-gray-100 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-xs 2xl:text-sm">
                {filteredLinks?.map((link) => (
                  <ComboboxOption
                    key={link.name}
                    value={link}
                    className="bg-gray-100 hover:bg-gray-200 relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-800 before:content-none my-0"
                  >
                    {({ selected }) => (
                      <span
                        className={classNames(
                          'block truncate',
                          selected && 'font-semibold'
                        )}
                      >
                        {link?.name}
                      </span>
                    )}
                  </ComboboxOption>
                ))}
              </ComboboxOptions>
            )}
          </div>
        </Combobox>
        <button
          className="clickToTurnWheel"
          type="button"
          disabled={isNil(selectedLink)}
          style={{
            color: superLink?.styles?.buttonTextColor,
            '--wheel-button-color': superLink?.styles?.buttonBackgroundColor,
            opacity: !isNil(selectedLink) ? 1 : '0.5',
          }}
          onClick={handleClick}
        >
          Participer
        </button>
      </div>
    </div>
  );
}

export default Links;
